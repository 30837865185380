import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '../views/Login.vue';

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location)
    .catch(err => {
      console.log(err);
    });
};

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "Dashboard" */ '../views/Dashboard.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/404',
    name: '404',
    component: () => import(/* webpackChunkName: "Error" */ '../views/404.vue'),
  },
  {
    path: '*',
    redirect: '/404'
  }
];

const router = new VueRouter({
  routes
});

const needCheckLoginRoutes = ['dashboard'];

router.beforeEach((to, from, next) => {
  if (Vue.ls.get('currentUser')) {
    if (to.fullPath === '/') {
      next('/dashboard');
    } else {
      next();
    }
  } else {
    if (needCheckLoginRoutes.includes(to.fullPath)) {
      next('/');
    } else {
      next();
    }
    next();
  }
});

export default router;
