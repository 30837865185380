<template>

    <el-card
            class="box-card">

        <el-row
                style="text-align: center; margin-bottom: 10px">

            <el-image
                    style="width: 100px; height: 100px"
                    :src="image"
                    :fit="'fill'"/>

            <h1 style="font-size: 24px">
                Login met uw account
            </h1>

        </el-row>

        <el-form
                label-width="120px">

            <el-form-item label="E-mailadres">
                <el-input
                        placeholder="Uw e-mailadres"
                        v-model="form.email"/>
            </el-form-item>

            <el-form-item label="Wachtwoord">
                <el-input
                        type="password"
                        placeholder="Uw wachtwoord"
                        v-model="form.password"/>
            </el-form-item>

            <el-form-item>
                <el-button
                        type="primary"
                        v-on:click="login">
                    Inloggen
                </el-button>
            </el-form-item>

        </el-form>

    </el-card>

</template>

<script>
export default {
    data: function () {
        return {
            form: {
                email: '',
                password: '',
            },
            image: require('@/assets/images/logo.png'),
            passwordShow: false,
        };
    },
    methods: {
        async login() {

            await this.$recaptchaLoaded();

            const token = await this.$recaptcha('login');

            this.form.captcha = token

            this.$http.post('login', this.form)
                .then(res => {
                    if (res) {
                        this.$ls.set('currentUser', res, 1000 * 60 * 60 * 24 * 7);
                        this.$router.push('dashboard');
                    }
                }).catch(e => {
                this.$message.error(e);
            });
        }
    },
};
</script>

<style lang="scss" scoped>

.box-card {
  width: 480px;
  margin-top: 80px;
  margin-left: auto;
  margin-right: auto;
}

</style>
