import Vue from 'vue';
import App from './App.vue';
import Storage from 'vue-ls';
import router from './router';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/scss/app.scss';
import 'element-ui/lib/theme-chalk/base.css';

import { dayjs } from './helpers/dayHelper';
import 'dayjs/locale/nl';

import {VueReCaptcha} from 'vue-recaptcha-v3'
const captcha = '6Ld9tF4lAAAAAOsTO9S3a80WqVXnin1I_ajPSMn8';
Vue.use(VueReCaptcha, { siteKey: captcha });

Vue.prototype.$dayjs = dayjs;

Vue.use(Storage, {
  namespace: 'marshall__', // key prefix
  name: 'ls', // name variable Vue.[ls] or this.[$ls],
  storage: 'local', // storage name session, local, memory
});

import axios from './helpers/httpHelper';
Vue.prototype.$http = axios;

import { i18n } from './helpers/langHelper';

Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value)
});
Vue.prototype.$i18 = i18n;

require('./helpers/filters');

Vue.config.productionTip = false;

new Vue({
  i18n,
  router,
  render: h => h(App)
}).$mount('#app');
