/**
 * Created by PhpStorm
 * Author Finley Ma <maf@shinetechsoftware.com>
 * Date: 2020/9/3
 * Time: 10:19 AM
 */

import Vue from 'vue';
import dayjs from 'dayjs';

/**
 *  global filters
 */

Vue.filter('minuteToTime', function (minute) {
  let hrs = parseInt(minute / 60);
  let min = parseInt(minute - (hrs * 60));
  min = min < 10 ? '0' + min : min;
  return hrs + ':' + min;
});

Vue.filter('money', function (number, decimal = 1) {
  return '€' + (number * decimal)
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, '$&,')
    .replace('.', ',');
});

Vue.filter('date', function (str, format = 'DD/MM/YYYY') {
  return dayjs(str)
    .format(format);
});

Vue.filter('unixToDate', function (timestamp, formatString = 'DD/MM/YYYY') {
  const unixTimestamp = (timestamp - 719528) * 86400;
  return dayjs.unix(unixTimestamp)
    .format(formatString);
});

Vue.filter('diffTime', function (datetime) {
  return dayjs(datetime)
    .fromNow(true);
});
