import axios from 'axios';
import {Message} from 'element-ui';
import Vue from 'vue';

const baseURL = process.env.VUE_APP_BASE_URL;
axios.defaults.crossDomain = true;

axios.interceptors.request.use(
    config => {
        config.url = `${baseURL}/marshall/${config.url}`;
        if (Vue.ls.get('currentUser')) {
            config.headers.common['Authorization'] = 'Bearer ' + Vue.ls.get('currentUser').api_token;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    });

axios.interceptors.response.use(function (response) {
    console.log(response);
    if (response.data && response.data.code >= 400) {
        this.$message.error(response.data.message);
        return Promise.reject(response);
    }
    return response.data;
}, function (error) {
    console.log('--- error response ---');
    console.log(error);

    let errorsObj = {};
    if (error.response.status >= 400) {
        let messages = [];
        errorsObj = error.response.data.errors || error.response.data.message;
        if (errorsObj.constructor === Object) {
            messages = Object.values(errorsObj)
                .flat();
        } else {
            messages.push(errorsObj);
        }
        if (error.response.status === 422) {
            Message({
                message: messages.join('<br>'),
                type: 'error',
                duration: 3 * 1000,
                dangerouslyUseHTMLString: true,
            });
        }
    }
    return Promise.reject(error);
});

export default axios;
