import Vue from 'vue'
import VueI18n from 'vue-i18n'
import enLocale from 'element-ui/lib/locale/lang/en'
import nlLocale from 'element-ui/lib/locale/lang/nl'

Vue.use(VueI18n);

const messages = {
  'en': {
    ...(require('../lang/en').message),
    ...enLocale,
  },
  'nl': {
    ...(require('../lang/nl').message),
    ...nlLocale,
  },
};

// https://kazupon.github.io/vue-i18n/guide/fallback.html#implicit-fallback-using-locales
export const i18n = new VueI18n({
  // silentFallbackWarn: true,
  // formatFallbackMessages: true,
  silentTranslationWarn: true,
  fallbackLocale: 'en',
  locale: 'nl',
  messages,
});

// end VueI18n
