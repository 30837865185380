/**
 * Created by PhpStorm
 * Author Finley Ma <maf@shinetechsoftware.com>
 * Date: 2020/6/24
 * Time: 10:49 AM
 */

export const message = {
  profile: 'Profile',
  employee: 'Employee',
  upload_photo: 'Upload photo',
  times: 'times',
  personal_details: 'Personal details',
  firstname: 'First name',
  lastname: 'Last name',
  prefix: 'Prefix',
  emailaddress: 'Emailaddress',
  system_lang: 'System lang.',
  activity_log: 'Activity Log',
  login_username: 'Login username',
  last_login: 'Last login',
  login_count: 'Login count',
  phone: 'Phone',
  relation_find: 'Find a relation...',
  btn: {
    save: 'Save'
  },
  statistics: {
    Statistics: 'Statistics',
    Day: 'Day',
    Week: 'Week',
    Month: 'Month',
    Year: 'Year',
    'Revenue': 'Revenue (x1000)',
    'Occupation': 'Occupation (%)',
    Visitors: 'Visitors',
    'CourseOccupation': 'Course Occupation',
    Temperature: 'Temperature',
    Relation: 'Relation',
    Business: 'Business',
  },
  live_up: {
    'view_all': 'View All',
    'teetime_booked': 'Teetime Booked',
    'players': 'players',
  },
  info_cards: {
    'Total day revenue': 'Total day revenue',
    'Teetime reservations': 'Teetime reservations',
    'Course Occupation': 'Course Occupation',
    'Processed Scorecards': 'Processed Scorecards',
  },
  'Traffic Channels': 'Traffic Channels',
  'Course Occupation Overview': 'Course Occupation Overview',
};
