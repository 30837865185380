/**
 * Created by PhpStorm
 * Author Finley Ma <maf@shinetechsoftware.com>
 * Date: 2020/6/24
 * Time: 10:49 AM
 */

export const message = {
  Mon: 'Maa',
  Tue: 'Di',
  Wed: 'Woe',
  Thu: 'Do',
  Fri: 'Vrij',
  Sat: 'Zat',
  Sun: 'Zon',
  January: 'Januari',
  February: 'Februari',
  March: 'Maart',
  April: 'April',
  May: 'Mei',
  June: 'Juni',
  July: 'Juli',
  August: 'Augustus',
  September: 'September',
  October: 'Oktober',
  November: 'November',
  December: 'December',
  jan: 'jan',
  feb: 'feb',
  mar: 'maa',
  apr: 'apr',
  may: 'mei',
  jun: 'jun',
  jul: 'jul',
  aug: 'aug',
  sep: 'sep',
  oct: 'okt',
  nov: 'nov',
  dec: 'dec',

  'Sunrise': 'Zondag',
  'Sunset': 'ZondZon onderag',
  'Dark': 'Donker',
  'Save': 'Opslaan',
  Now: 'Nu',
  Today: 'Vandaag',
  Tomorrow: 'Morgen',
  'Find a relation..': 'Zoek een relatie..',
  'Teetime Move Mode': 'Starttijden verplaatsen',
  'Only show available times': 'Toon alleen beschikbare tijden',
  'Show not registered flights': 'Toon niet aangemelde flights',
  'Course maintenance': 'Baanonderhoud',
  'Close': 'Sluiten',
  'Retrieving data and building the teesheet...': 'Beschikbare tijden laden...',
  'When move mode enabled, we can move one teetime, otherwise click one row will enter flight detail page': 'Wanneer deze optie actief is, kun je flights verplaatsen door er op te klikken. Flights zullen dan niet geopend worden.',

  'Move a teetime': 'Flight verplaatsen',
  'Loading all available teetimes...': 'Beschikbare tijden laden...',
  'Select a date': 'Selecteer een datum',
  'Selected teetime': 'Geselecteerde starttijd',
  'Move to': 'Verplaats naar',
  'Available move options for selected teetime': 'Beschikbare opties om te verplaatsen',
  'No options found yet, select a date first': 'Nog geen opties, selecteer eerst een datum',
  'Selected date': 'Geselecteerde datum',
  'No, cancel': 'Nee, cancel',
  'Cancel': 'Cancel',
  'Yes, move': 'Ja, verplaats',
  'Confirm move teetime': 'Bevestig verplaatsing',
  'Are you sure to move this teetime?': 'Weet je zeker dat je deze flight wil verplaatsen?',
  'Old': 'Oud',
  'New': 'Nieuw',
  'Edit': 'Wijzig',
  'Confirm': 'Bevestig',
  'Confirm move': 'Bevestig verplaatsing',
  'Teetime moved successfully!': 'Flight succesvol verplaatst!',
  'You can\'t move an empty teetime!': 'Hier staat geen flight om te verplaatsen!',

  'Add course maintenance': 'Baanonderhoud toevoegen',
  'Date range': 'Datum(s)',
  'Date from': 'Van ',
  'Date to': 'Tot',
  'Time from': 'Van ',
  'Time to': 'Tot',
  'Courseloops': 'Baanlussen',
  'Type maintenance': 'Soort onderhoud',
  'Event': 'Evenement',
  'Groupbooking': 'Groepsboeking',
  'Maintenance': 'Onderhoud',
  'Add maintenance': 'Baanonderhoud toevoegen',

  'Flight maintenance': 'Flight onderhoud',
  'Back link': 'Terug naar de planner',
  'Flight of': 'Flight van',
  'Through at:': 'Doorloop op:',
  'None': 'Geen doorloop',
  'Throught at:': 'Doorloop op:',
  'Save & Close': 'Opslaan & sluiten',
  'Cancel Flight': 'Flight annuleren',
  'Check-in': 'Check-in',
  'Delete the whole flight?': 'Hele flight verwijderen?',
  'Please select cancel reason below:': 'Selecteer de annuleringsreden:',
  'Yes, delete': 'Ja, verwijderen',
  'Cancel flight successfully': 'Flight succesvol verwijderd',
  'Flight info': 'Flight info',
  'Date': 'Datum',
  'Through hole 10': 'Doorloop hole 10',
  'Number of players': 'Aantal spelers',
  'At courseloop': 'Op baanlus',
  'Players': 'Spelers',
  'Player': 'Speler',
  'Email Address': 'E-mailadres',
  'Phone': 'Tel.',
  'Rate': 'Tarief',
  'Price': 'Prijs',
  'Item': 'Item',
  'Open Membercard': 'Relatiekaart openen',
  'Remove player': 'Speler verwijderen',
  'Search for a player...': 'Zoek een speler...',
  'Phone number': 'Telefoonnummer',
  'Found relations': 'Gevonden relaties',
  'No relations found': 'Geen relaties gevonden',
  'M/F': 'M/V',
  'City': 'Woonplaats',
  'Day of birth': 'Geboortedatum',
  'Played history': 'Speelgeschiedenis',
  'Membercard': 'Relatiekaart',
  'View all personal details of this member': 'Bekijk alle persoonlijke details van deze speler',
  'HGP-number': 'NGF-nr.',
  'Mobile Number': 'Mobiel nr.',
  'Street': 'Straat',
  'Zipcode': 'Postcode',
  'Country': 'Land',
  'Rates': 'Tarieven',
  'Sales': 'Verkoop',
  'Discount': 'Korting',
  'Total price': 'Totale prijs',
  'Rent items': 'Verhuuritems',
  'Rent_items': 'Verhuuritems',
  'No rentable items added': 'Geen verhuuritems toegevoegd',
  'Add rentable items to this flight via the \'Add item\' button in the flight corner': 'Voeg verhuuritems toe aan deze flight via de \'Item toevoegen\' knop rechtsbovenin.',
  'Add rentable item': 'Verhuuritem toevoegen',
  'Update rentable item': 'Update verhuuritem',
  'Select...': 'Selecteer...',
  'Select player': 'Selecteer een speler',
  'Select item': 'Selecteer een item',
  'Select price': 'Selecteer de prijs',
  'Select rate': 'Selecteer tarief',
  'Select discount': 'Selecteer korting',
  'Add History Player': 'Geschiedenisspeler toevoegen',
  'Add item': 'Item toevoegen',
  'Item added successfully': 'Item succesvol toegevoegd',
  'Receipt #': 'Bon nr.',
  'Date & Time': 'Datum & tijd',
  'VAT': 'BTW',
  'Total amount': 'Totale prijs',
  'Printed?': 'Geprint?',
  'Refund': 'Terugboeken',
  'Mail receipt': 'Mail bon',
  'Print receipt': 'Print bon',
  'Not found relations': 'Geen relaties gevonden',
  'Not found records': 'Geen data gevonden',
  'Membercard & played history': 'Spelerskaart & speelhistorie',
  'Are you sure to remove this player from this flight?': 'Weet u zeker dat u deze speler van de flight wil verwijderen?',
  'Removing player 1 from the flight will cancel the entire flight': 'Het verwijderen van speler 1 zal de gehele flight annuleren',
  'Please select cancel reason below': 'Selecteer een annuleringsreden',
  'Are you sure to add {name} to players list ?': 'Weet u zeker dat u {name} spelers wil toevoegen?',
  'Are you sure to refund this item ?': 'Weet je zeker dat u dit item wil terugboeken?',
  'You can\'t refund this item': 'Je kunt dit item niet terugboeken',
  'Are you sure to delete this rent item': 'Weet je zeker dat u dit verhuuritem wil verwijderen?',
  'Delete rent item': 'Verwijder verhuuritem',
  'Delete success': 'Succes verwijderen',
  'Can\'t cancel the flight that have player already paid': 'Flight kan niet worden geannuleerd, er heeft al een speler betaald',
  'You can\'t add duplicate player': 'Je kunt geen dubbele speler toevoegen',

  // user profile
  'Profile -': 'Profiel van -',
  'Profile': 'Profiel',
  'Back to relation overview': 'Terug naar relatielijst',
  'Nickname': 'Bijnaam',
  'Name': 'Naam',
  'Initials': 'Initialen',
  'Prefix': 'Voorvoegsel',
  'First name': 'Voornaam',
  'Last name': 'Achternaam',
  'Search code': 'Zoek code',
  'Sex': 'Geslacht',
  'Male': 'Man',
  'Female': 'Vrouw',
  'Rather not say': 'Zeg ik liever niet',
  'Nationality': 'Nationaliteit',
  'Zipcode City': 'Postcode Woonplaats',
  'Emailaddress': 'E-mailadres',
  'Mobile phone': 'Mobiel tel.',
  'Add extra note': 'Notitie toevoegen',
  'Member since': 'Lid sinds',
  'Member until': 'Lid tot',
  'Course permission': 'Baanpermissie',
  'Pro permission': 'Propermissie',
  'Rule exam': 'Regelexamen',
  'Fysical NGF-Card': 'Fysieke NGF-pas',
  'Yes': 'Ja',
  'No': 'Nee',

  'Relationgroups': 'Relatiegroep',
  'Financial': 'Financieel',
  'Information': 'Informatie',
  'Notes': 'Notitie',
  'Personal details': 'Persoonlijke Details',

  'Add profile': 'Profiel toevoegen',
  'Edit profile': 'Bewerk profiel',
  'New photo': 'Nieuwe profielfoto',
  'Select country': 'Selecteer land',
  'Same as postaddress': 'Hetzelfde als postadres',
  'Debtor-number': 'Debiteurnummer',
  'Credit limit': 'Krediet limiet',
  'VAT-number': 'BTW-nummer',
  'Only mandatory for businesses': 'Alleen noodzakelijk voor zakelijke accounts',
  'Direct debit?': 'Automatische incasso?',
  'Precept number': '',
  'Percept date': '',
  'Send invoice per': 'Stuur facturen via',
  'Send invoice reminders': 'Stuur betalingsherinneringen',
  'Send reminders to': 'Stuur herinneringen naar',
  'Use default emailaddress': 'Gebruik standaard e-mailadres',
  'Add free field': 'Vrij veld toevoegen',
  'Free field': 'Vrij veld',
  'Free fields can be added for extra information which the system doesn\'t prodvide by default': 'Informatie die het systeem niet standaard opslaat, kan worden toegevoegd via vrije velden',
  'Add a new free field': 'Nieuw vrij veld toevoegen',
  'Find a suitable name for the new field': 'Verzin een passende naam voor het veld',
  'Add new note': 'Nieuwe notitie toevoegen',
  'Add a new note': 'Nieuwe notitie toevoegen',
  'Delete Note': 'Notitie verwijderen',
  'Are you sure to delete this note?': 'Weet je zeker dat je deze notitie wilt verwijderen?',
  'You can type here whatever you want to': 'U kunt hier uw notitie typen',
  'Add note': 'Notitie toevoegen',
  'Save Note': 'Notitie opslaan',
  'New Note': 'Nieuwe notitie',

  // relation list
  'Age': 'Leeftijd',
  'Gender': 'Geslacht',
  'Membership date': 'Lid sinds',
  'Membership': 'Lidmaatschap',
  'Both': 'Beide',

  employee: 'Gebruiker',
  upload_photo: 'Foto uploaden',
  times: 'keer',
  save: 'Opslaan',
  personal_details: 'Persoonlijke gegevens',
  firstname: 'Voornaam',
  lastname: 'Achternaam',
  prefix: 'Voorvoegsel',
  emailaddress: 'E-mailadres',
  system_lang: 'Taal',
  activity_log: 'Activiteiten logboek',
  login_username: 'Inlognaam',
  last_login: 'Laatste login',
  login_count: 'Aantal logins',
  phone: 'Tel.',
  relation_find: 'Zoek een relatie...',
  btn: {
    save: 'Opslaan'
  },
  statistics: {
    Statistics: 'Statistieken overzicht',
    Day: 'Dag',
    Week: 'Week',
    Month: 'Maand',
    Year: 'Jaar',
    'Revenue': 'Omzet (x1000)',
    'Occupation': 'Bezetting (%)',
    Visitors: 'Bezoekers',
    'CourseOccupation': 'Baanbezetting',
    Temperature: 'Temperatuur',
    Relation: 'Relatie',
    Business: 'Zakelijk',
  },
  live_up: {
    'view_all': 'Bekijk alles',
    'teetime_booked': 'Starttijd geboekt',
    'players': 'spelers',
  },
  info_cards: {
    'Total day revenue': 'Totale dagomzet',
    'Teetime reservations': 'Starttijd boekingen',
    'Course Occupation': 'Baanbezetting',
    'Processed Scorecards': 'Verwerkte scorekaarten',
  },
  'Traffic Channels': 'Boekingskanalen',
  'Course Occupation Overview': 'Overzicht baanbezetting',
  'Retrieving data from teesheet...': 'Overzicht baanbezetting...',
  'Version': 'Versie',
  'What\'s new?': 'Wat is nieuw?',
  'Release date': 'Release datum',
  'Added features': 'Toegevoegde functionaliteit',
  'Crushed bugs': 'Verholpen bugs',
  'Other improvements': 'Andere verbeteringen',
  'Okay': 'Oké',
};
